import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    console.log ('\n\nHEY JIMBO interview/\n\n')
    return (
      <div>
        {/* <section className="section page">
          <article className="content"><h2 style={{ textAlign: "center" }}>Coming Soon</h2></article>
        </section> */}
        {posts &&
          posts.map(({ node: post }) => (
              <article className="grid roll" key={post.id}>
                <div className="grid--item">
                  <h2 className="number">
                  {/* <span className="number"></span> */}{post.frontmatter.number} 
                  
                  </h2>
                  </div>
                  <div className="grid--item center">
                    <h3>
                    
                  
                    <Link
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}<span className="number"> {post.frontmatter.description}</span>

                    </Link>
                    </h3>
                  </div>
                  <div className="grid--item">
                      <Link to={post.fields.slug} className="button">
                        →
                      </Link>
                </div>
              </article>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                number
                templateKey
                date(formatString: "MMMM DD, YYYY")
                tags
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
